import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FormDialog =(props)=> {
 
    const settingsData = useSelector(({settings}) => settings.settingsData);

    const {onClose, open, title, desc, body, onSubmit} = props;

    let hasSubmit = true;


   let maxWidth = "sm";
    

    if (props.hasSubmit != null){
      hasSubmit = props.hasSubmit; 
    }

    if (props.maxWidth != null){
      maxWidth = props.maxWidth; 
    }
    
    const trans = settingsData.trans;

  return (
    <div>
      <Dialog sx={{ m: 0, p: 2 }}  fullWidth={true} open={open} maxWidth={maxWidth} onClose={onClose}>
        <DialogTitle style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <span>{title}</span>
        <IconButton onClick={onClose}>
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {desc}
          </DialogContentText>
            {body}
        </DialogContent>
        {hasSubmit ? 
        <DialogActions>
          <Button disabled={props.onSubmitLoading} onClick={onClose}>{trans.cancel_btn}</Button>
          <Button disabled={props.onSubmitLoading} onClick={onSubmit}>{trans.submit_btn}</Button>
        </DialogActions> : null}
      </Dialog>
    </div>
  );
}

export default FormDialog;
