import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  USER_DATA,
  POST_CREATE_ACCOUNTS
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  signInData: null,
  logoutData: null,
  userInfoData: null,
  createAccountsData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SIGN_IN:
                   return {
                     ...state,
                     signInData: action.payload
          }
          case POST_SIGN_OUT:
            return {
              ...state,
              logoutData: action.payload
          }
          case POST_USER_INFO:
            return {
              ...state,
              userInfoData: action.payload
          }
           case POST_CREATE_ACCOUNTS:
            return {
              ...state,
              createAccountsData: action.payload
          }
         
         
        default:
              return state;
        }
};
      
export default reducer;