import {
  POST_UPLOAD_IMAGE,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postUploadImage = (formData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  formData.append('langLoc', userLocale);

  const response = await axios.post(`upload/image`,formData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_UPLOAD_IMAGE, payload: response.data});
};

