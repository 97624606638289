import './companies.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postCompanyAdd, postCompaniesAll, postCompanyEdit, } from '../../redux/company/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './EditCompanyItem';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';

const Company = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const companiesData = useSelector(({companies}) => companies.companiesData);
  const addCompanyData = useSelector(({companies}) => companies.addCompanyData);
  const editCompanyData = useSelector(({companies}) => companies.editCompanyData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [companiesArr,setCompanyArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [bannerTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (companiesData != null){
     // console.log("companiesData",companiesData);
      setCompanyArr(companiesData.companies);
      // setTotalData(companiesData.total);
      setLoadedFirstTime(true);
    }
  }, [companiesData]);

  useEffect(() => { //ADD DATA
    if (addCompanyData != null){
      if (addCompanyData.status) {
        toast.dismiss();
        toast.success(addCompanyData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addCompanyData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_COMPANY_ADD, payload: null});
    }
  }, [addCompanyData]);

  useEffect(() => { //EDIT DATA
    if (editCompanyData != null){
      if (editCompanyData.status) {
        toast.dismiss();
        toast.success(editCompanyData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editCompanyData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_COMPANY_EDIT, payload: null});
    }
  }, [editCompanyData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
  useEffect(() => { 
    if (bannerTypeSelect != null && bannerTypeSelect && bannerTypeSelect != 0){
      reloadData();
    }
  }, [bannerTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false
    };

    dispatch(postCompaniesAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_banner_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postCompanyEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setEditItem(null);
      dispatch(postCompanyAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_banner_title)
    setEditItem(item);
    setAddModal(true);
  }



  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  //UI
  const columnData = [
    {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
    {id: "title", align: true, disablePadding: false, label: trans.table_head_title},
    {id: "web_link", align: true, disablePadding: false, label: trans.table_head_web_link},
    {id: "email", align: true, disablePadding: false, label: trans.table_head_email},
    {id: "phone", align: true, disablePadding: false, label: trans.table_head_phone},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at}
  ];

  const onChangeUserType = (value) => {
    setSearchTypeSelect(value);
  }
  
  const onChangeAccountStatus = (value) => {
    setAccountStatusSelect(value);
  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_companies}</CustomTitle>
          <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button>
        </div>
        <br/>
        {/* <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={8}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard" 
                disabled={(bannerTypeSelect != 0 && bannerTypeSelect != "all") || (accountStatusSelect != "none"  && accountStatusSelect != "all") }
                onChange={(e) => searchChanged(e.target.value)}
              />
          </Grid>   
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.banner_type_select}</InputLabel>
              <Select
                    variant="standard"
                    labelId="bannerTypeSelect"
                    id="bannerTypeSelect"
                    value={bannerTypeSelect}
                    label={trans.banner_type_select}
                    onChange={(e) => onChangeUserType(e.target.value)}>
                      <MenuItem key={"banner_type_0"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"banner_type_1"} value={1}>{trans.bannerTypeDriver}</MenuItem> 
                      <MenuItem key={"banner_type_2"} value={2}>{trans.bannerTypePassenger}</MenuItem>
                      <MenuItem key={"banner_type_3"} value={3}>{trans.bannerTypeBoth}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.table_head_account_status}</InputLabel>
              <Select
                    variant="standard"
                    labelId="accountStatusSelect"
                    id="accountStatusSelect"
                    value={accountStatusSelect}
                    label={trans.table_head_account_status}
                    onChange={(e) => onChangeAccountStatus(e.target.value)}>
                          <MenuItem key={"account_status_none"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"account_status_aproved"} value={"approved"}>{trans.account_status_approved}</MenuItem> 
                      <MenuItem key={"account_status_rejected"} value={"rejected"}>{trans.account_status_rejected}</MenuItem>
                      <MenuItem key={"account_status_pending"} value={"pending"}>{trans.account_status_pending}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
       </Grid> */}
       
        <div>
         <br/>
          <Table size="small" style={{display:'block', overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                <TableCell/>
                <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {companiesArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.img_thumb_url == "#" ? null : <img width="130" className='_companies_img'  src={row.img_thumb_url}></img>}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.web_link}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{getLocalDate(row.created_at)}</TableCell>
                    <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onViewRow(e,row)}  variant="outlined">{trans.view_item_title}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_company_title : trans.edit_company_title }`}
        body={<EditUserStatus toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

</Container>);
}

export default Company;