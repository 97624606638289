import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import { getLocalDate, isItemValid } from '../../helpers/General';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditUserPackages =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [packagesItems,setPackagesItems] = useState([]);
    const [selectedPackagesItems,setSelectedPackagesItems] = useState([]);
    const [toSendPush,setToSendPush] = useState(false);


    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = {
            userId: editItem.id,
            access_packages: selectedPackagesItems,
            toSendPush: toSendPush
          };

        
          props.onSubmitData(postData)
      }
    }, [props.toGetData]);

    useEffect(() => {
      if (editItem != null){
        setPackagesItems(settingsData.settings.pruchasePackages);
        setSelectedPackagesItems(editItem.access_packages)
      } else {
        clearData();
      }
    },[editItem]);

  
    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
      setPackagesItems([[]]);
      setSelectedPackagesItems([]);
    }

    const handleOnSelected = (e, item) => {
     
      var updatedList = [...selectedPackagesItems];
      var itemExists = false;
      var itemIndex = 0;

      updatedList.forEach((element,index) => {
        if (element.pack_id == item.id){
          itemExists = true;
          itemIndex = index;
        } 
      });

      if (itemExists){
        updatedList.splice(itemIndex,1);
      } else {
        updatedList.push({
          pack_id: item.id,
        });
      }

      setSelectedPackagesItems(updatedList);

    }


    //UI
    if (editItem == null){
      return (<div></div>)
    } else {
    return (
      <div>
        <Grid container spacing={3} alignItems="center">

        <Grid item xs={12}>
            <div><b>{editItem.fullName} • {editItem.email}</b></div>
          </Grid>
          
          <Grid item xs={12}>
            <div className='_flex_row'>   
              <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={ toSendPush}
                  onChange={(e,value) => setToSendPush(e.target.checked)}/>
                <div> {trans.to_send_packages_push}</div>
              </div>
            </Grid>

            <Grid item xs={12}>
                <hr className='_split_line'/>
            </Grid>

          <Grid item xs={12}>
            <div>{trans.packages_title}</div>
            <br/>
              {packagesItems.map(item => (
                <div key={item.id}>
                   <div className='_flex_row'>   
                    <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={ selectedPackagesItems.find(element => element.pack_id == item.id) != null}
                            onChange={(e,value) => handleOnSelected(e,item)}
                      />
                      <div><b>{item.name}</b></div>
                     
                  </div>
                    <div style={{marginLeft: "45px"}}>
                        <div>{trans.package_routes}: {item.routes}</div>
                        <div>{trans.package_amount}: {item.amount}€</div>
                    </div>
                  
                    <hr className='_split_line'/>
                </div>
              ))}
           
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default EditUserPackages;
