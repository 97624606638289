import {
  POST_COUNTRY_ALL,
  POST_COUNTRY_EDIT_FUEL
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postCountriesAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`settings/countries`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUNTRY_ALL, payload: response.data});
};

    
export const postCountriesEditFuel = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`admin/country-fuel-edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUNTRY_EDIT_FUEL, payload: response.data});
};

