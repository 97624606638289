import './countries.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { PRIMARY_COLOR } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COUNTRY_EDIT_FUEL } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postCountriesAll, postCountriesEditFuel } from '../../redux/countries/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditCountryItem from './EditCountryItem';
import TablePagination from '@mui/material/TablePagination';
import InventoryIcon from '@mui/icons-material/Inventory';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import EditIcon from '@mui/icons-material/Edit';
import SweetAlert from 'sweetalert2';
import { getLocalDate, getUserTypeText } from '../../helpers/General';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Countries = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const countriesData = useSelector(({countries}) => countries.countriesData);
  const editCountryFuelData = useSelector(({countries}) => countries.editCountryFuelData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [countriesArr,setCountriesArr] = useState([]);
  const [countriesArrHolder,setCountriesArrHolder] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"name": 1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);


  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (countriesData != null){
      setCountriesArr(countriesData.countries);
      if (loadedFirstTime == false){
        //HOLD THEM TO RETRIEVE THEM LATER ON THE SEARCH EMPTY
       setCountriesArrHolder(countriesData.countries);
      }
      setLoadedFirstTime(true);
    }
  }, [countriesData]);


  useEffect(() => { //EDIT DATA
    if (editCountryFuelData != null){
      if (editCountryFuelData.status) {
        toast.dismiss();
        toast.success(editCountryFuelData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editCountryFuelData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_COUNTRY_EDIT_FUEL, payload: null});
    }
  }, [editCountryFuelData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      if (searchKeyword.length >= 3){
        reloadData();
      } else {
        if (countriesArrHolder.length > 0){
          setCountriesArr(countriesArrHolder);
        }
      }
    }
  }, [searchKeyword]);
  
  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false,
      sort,
      searchKeyword: searchKeyword
    };

    dispatch(postCountriesAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_country_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postCountriesEditFuel(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setEditItem(null);
      dispatch(postCountriesEditFuel(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_country_title)
    setEditItem(item);
    setAddModal(true);
  }



  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  //UI
  const columnData = [
    {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
    {id: "name", align: true, disablePadding: false, label: trans.table_head_name},
    {id: "gasPrice", align: true, disablePadding: false, label: trans.table_head_gasoline},
    {id: "dieselPrice", align: true, disablePadding: false, label: trans.table_head_diesel},
    {id: "lpgPrice", align: true, disablePadding: false, label: trans.table_head_lpg},
    {id: "evPrice", align: true, disablePadding: false, label: trans.table_head_ev}
  ];

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_countries}</CustomTitle>
          {/* <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button> */}
        </div>
        <br/>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={12}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard" 
                onChange={(e) => searchChanged(e.target.value)}
              />
          </Grid>   
         
        
       </Grid>
       
        <div>
         <br/>
          <Table size="small" style={{display:'block', overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                <TableCell/>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                </TableRow>
            </TableHead>
            <TableBody>
                {countriesArr.map((row,index) => (
                  <TableRow key={row.id}>
                     <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.icon == "#" ? null : <img width="40" className='_countries_img' alt={`country_${row.name}_img`} src={row.icon}></img>}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.gasPrice}€</TableCell>
                    <TableCell>{row.dieselPrice}€</TableCell>
                    <TableCell>{row.lpgPrice}€</TableCell>
                    <TableCell>{row.evPrice}€</TableCell>
                    <TableCell><Button startIcon={<EditIcon />} size="small" color="secondary" onClick={(e) => onViewRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_country_title : trans.edit_country_title }`}
        body={<EditCountryItem toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

</Container>);
}

export default Countries;