import './popups.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { PRIMARY_COLOR } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_POPUP_ADD, POST_POPUP_DELETE, POST_POPUP_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postPopupsAdd, postPopupsAll, postPopupsDelete, postPopupsEdit, } from '../../redux/popups/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './EditPopupItem';
import TablePagination from '@mui/material/TablePagination';
import InventoryIcon from '@mui/icons-material/Inventory';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import SweetAlert from 'sweetalert2';
import { getLocalDate, getUserTypeText } from '../../helpers/General';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';

const Popups = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const popupsData = useSelector(({popups}) => popups.popupsData);
  const addPopupData = useSelector(({popups}) => popups.addPopupData);
  const editPopupData = useSelector(({popups}) => popups.editPopupData);
  const deletePopupData = useSelector(({popups}) => popups.deletePopupData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [popupsArr,setPopupsArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [popupTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (popupsData != null){
      console.log("popupsData",popupsData);
      setPopupsArr(popupsData.app_popups);
      // setTotalData(popupsData.total);
      setLoadedFirstTime(true);
    }
  }, [popupsData]);

  useEffect(() => { //ADD DATA
    if (addPopupData != null){
      if (addPopupData.status) {
        toast.dismiss();
        toast.success(addPopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addPopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POPUP_ADD, payload: null});
    }
  }, [addPopupData]);

  useEffect(() => { //EDIT DATA
    if (editPopupData != null){
      if (editPopupData.status) {
        toast.dismiss();
        toast.success(editPopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editPopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POPUP_EDIT, payload: null});
    }
  }, [editPopupData]);


  useEffect(() => { //EDIT DATA
    if (deletePopupData != null){
      if (deletePopupData.status) {
        toast.dismiss();
        toast.success(deletePopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(deletePopupData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POPUP_DELETE, payload: null});
    }
  }, [deletePopupData]);

  

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
  useEffect(() => { 
    if (popupTypeSelect != null && popupTypeSelect && popupTypeSelect != 0){
      reloadData();
    }
  }, [popupTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false
    };

    dispatch(postPopupsAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_popup_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postPopupsEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setEditItem(null);
      dispatch(postPopupsAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_popup_title)
    setEditItem(item);
    setAddModal(true);
  }



  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  //UI
  const columnData = [
    {id: "title", align: true, disablePadding: false, label: trans.table_head_title},
    {id: "position", align: true, disablePadding: false, label: trans.table_head_position},
    {id: "status", align: true, disablePadding: false, label: trans.table_head_status},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at}
  ];

  const onChangeUserType = (value) => {
    setSearchTypeSelect(value);
  }
  
  const onChangeAccountStatus = (value) => {
    setAccountStatusSelect(value);
  }

 
  const onDeleteRow = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.btn_delete,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          //REMOVE
          let postData = {itemId: item.id}
          dispatch(postPopupsDelete(postData));
      }
    })
  }
  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_popups}</CustomTitle>
          <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button>
        </div>
        <br/>
       
       
        <div>
         <br/>
          <Table size="small" style={{ overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                <TableCell/>
                <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {popupsArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.position}</TableCell>
                    <TableCell>{`${trans[`status_${row.status}`]}`}</TableCell>
                    <TableCell>{getLocalDate(row.created_at)}</TableCell>
                    <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onViewRow(e,row)}  variant="outlined">{trans.view_item_title}</Button></TableCell>
                    <TableCell><Button startIcon={<DeleteIcon />} size="small"  color="error" onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.btn_delete}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_popup_title : trans.edit_popup_title }`}
        body={<EditUserStatus toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

</Container>);
}

export default Popups;