import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import { getLocalDate, isItemValid } from '../../helpers/General';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditUserStatus =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [address,setAddress] = useState("");
    const [mobile,setMobile] = useState("");
    const [mobileCode,setMobileCode] = useState("");
    const [mobileCodeImg,setMobileCodeImg] = useState("");
    const [approved,setApproved] = useState(false);
    const [accountStatus,setAccountStatus] = useState("pending");
    const [changedByAdmin,setChangedByAdmin] = useState(false);
    const [rejectReason,setRejectReason] = useState("");
    const [rejectReasonOther,setRejectReasonOther] = useState("");

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = {
            userId: editItem.id,
            approvedStatus: approved,
            admin_checked: changedByAdmin,
            reason: rejectReason == trans.reject_reason_other ? rejectReasonOther : rejectReason,
            other_reject_reason: rejectReason == trans.reject_reason_other
          };

        
          props.onSubmitData(postData)
      }
    }, [props.toGetData]);

    useEffect(() => {
      if (editItem != null){
          setFirstName(editItem.firstname);
          setLastName(editItem.lastname);
          setAddress(editItem.address);
          setMobile(editItem.mobile);
          setMobileCode(editItem.mobilecode);
          setApproved(editItem.approved);
          setRejectReason(editItem.reject_reason);
          setChangedByAdmin(editItem.admin_checked);

          if (editItem.approved == true && editItem.admin_checked == true){
            setAccountStatus(true);
          }
        
          if (editItem.approved == false && editItem.admin_checked == true){
            setAccountStatus(false);
          }

          if (editItem.approved == false && editItem.admin_checked == false){
            setAccountStatus("pending");
          }


          if (editItem.other_reject_reason == true){
            setRejectReason(trans.reject_reason_other);
            setRejectReasonOther(editItem.reject_reason);
          }
          countriesData.countries.forEach(country => {
            if (country.phone_code == editItem.mobilecode){
              setMobileCodeImg(country.icon)
            }
          });

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toGetData){
            // let tagsIds = selectedTags.map((value) => value.id);

            // let postData = {name,visible,slug,defaultTags:tagsIds,order};
            // props.onSubmitData(postData)
        }
      }, [props.toGetData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setFirstName("");
        setLastName("");
        setApproved(false);
        setAddress("");
    }

    const openUserDirections = () => {
      var url = "https://maps.google.com/?q=" + editItem.lat + "," + editItem.lng;
      window.open(url);   
    }

    const changeAprovedStatus = (value) => {
      console.log("value",value);
      if (value == "pending"){
        setChangedByAdmin(false);
        setAccountStatus("pending");
        setApproved(false);
      } else {
        setChangedByAdmin(true);
        setAccountStatus(value);
        setApproved(value);
      }

    }


    //UI
    if (editItem == null){
      return (<div></div>)
    } else {
    return (
      <div>
        <Grid container spacing={3} alignItems="center">

            <Grid item xs={12}>
              <div>
                <h4>{trans.photo_profile}</h4>
                {editItem.img_thumb_url == "#" || editItem.img_thumb_url == "" ? <span style={{fontSize:'13px'}}>{trans.no_photo_profile_yet}</span> :
                <a href={editItem.img_url} target="_blank" rel="noreferrer"> <img height={"100px"} src={editItem.img_thumb_url} ></img> </a> }
              </div>
            </Grid>

            <Grid item xs={12}>
                <hr className='_split_line'/>
            </Grid>

            <Grid item xs={12}>
              <h4>{trans.user_details}</h4>
              <Grid container spacing={5} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="firstName"
                    label={trans.firstname_field}
                    value={firstName}
                    type="text"
                    onChange={(e)=> {setFirstName(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="lastName"
                    label={trans.lastname_field}
                    value={lastName}
                    type="text"
                    onChange={(e)=> {setLastName(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid xs={9}>  
                <TextField
                    margin="dense"
                    id="address"
                    label={trans.address_field}
                    value={address}
                    type="text"
                    onChange={(e)=> {setAddress(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" /> 
                </Grid> 
                <Grid xs={2}>  
                  <Button 
                      startIcon={<DirectionsIcon />} 
                      size="small" 
                      color="secondary" 
                      onClick={(e) => openUserDirections(e)}  
                      variant="outlined">{trans.address_map_btn}
                  </Button> 
                </Grid>
              </Grid>
            </Grid>

         
            <Grid item xs={1}>
              {mobileCodeImg != "" && mobileCodeImg != "#" ?  <img style={{width: "35px"}} src={mobileCodeImg} alt="Code Img"></img> : null}
            </Grid> 

            <Grid item xs={2}>
              <TextField
                margin="dense"
                id="mobile"
                label={trans.mobile_code_field}
                value={mobileCode}
                type="text"
                onChange={(e)=> {setMobileCode(e.target.value)}}
                fullWidth
                disabled
                variant="standard" />
            </Grid>

            <Grid item xs={9}>
              <TextField
                margin="dense"
                id="mobile"
                label={trans.mobile_field}
                value={mobile}
                type="text"
                onChange={(e)=> {setMobile(e.target.value)}}
                fullWidth
                disabled
                variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <hr className='_split_line'/>
            </Grid>
           
            <Grid item xs={12}>
              <h4><b>{trans.user_id_card}</b></h4>
              <div className='_flex_row_space'> 
                <span className='_text_info_14'><b>{trans.id_number}</b>: {isItemValid(editItem.id_card_num) ? editItem.id_card_num : trans.detail_not_added}</span>
                <span className='_text_info_14'><b>{trans.expire_date}</b>: {getLocalDate( editItem.id_card_expire * 1000)}</span>
              </div>
            
              <br/>
               <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid xs={6}>  
                    <div >
                      <div className='_text_info_14'><b>{trans.user_id_card_front}</b></div>
                      {isItemValid(editItem.id_card_img_front_url) && isItemValid(editItem.id_card_img_thumb_front_url) ? 
                      <a href={editItem.id_card_img_front_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.id_card_img_thumb_front_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div>
                  </Grid>
                  <Grid xs={6}> 
                  <div >
                      <div className='_text_info_14'><b>{trans.user_id_card_back}</b></div>
                      {isItemValid(editItem.id_card_img_back_url) && isItemValid(editItem.id_card_img_back_thumb_url) ? 
                      <a href={editItem.id_card_img_back_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.id_card_img_back_thumb_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div> 
                  </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4><b>{trans.driver_id_card}</b></h4>
              <div className='_flex_row_space'> 
                <span className='_text_info_14'><b>{trans.driver_id_number}</b>: {isItemValid(editItem.driving_license_num) ? editItem.driving_license_num : trans.detail_not_added}</span>
                <span className='_text_info_14'><b>{trans.driver_expire_date}</b>: {getLocalDate( editItem.driving_license_expire * 1000)}</span>
              </div>
            
              <br/>
               <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid xs={6}>  
                    <div >
                      <div className='_text_info_14'><b>{trans.driver_id_card_front}</b></div>
                      {isItemValid(editItem.driving_license_img_front_url) && isItemValid(editItem.driving_license_img_thumb_front_url) ? 
                      <a href={editItem.driving_license_img_front_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.driving_license_img_thumb_front_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div>
                  </Grid>
                  <Grid xs={6}> 
                  <div >
                      <div className='_text_info_14'><b>{trans.driver_id_card_back}</b></div>
                      {isItemValid(editItem.driving_license_img_back_url) && isItemValid(editItem.driving_license_img_back_thumb_url) ? 
                      <a href={editItem.driving_license_img_back_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.driving_license_img_back_thumb_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div> 
                  </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <hr className='_split_line'/>
            </Grid>

            <Grid item xs={12}>
              <h4>{trans.account_status_title}</h4>
                <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_account_status}</InputLabel>
                  <Select
                      labelId="account_status"
                      id="approved"
                      value={accountStatus}
                      label={trans.table_head_account_status}
                      onChange={(e) => changeAprovedStatus(e.target.value)}>
                      <MenuItem key={"change_account_status_aproved"} value={true}>{trans.account_status_approved}</MenuItem>
                      <MenuItem key={"change_account_status_rejected"} value={false}>{trans.account_status_rejected}</MenuItem>
                      <MenuItem key={"change_account_status_pending"} value={"pending"}>{trans.account_status_pending}</MenuItem>
                  </Select>
                  </FormControl>
            </Grid>
           
           {accountStatus == false ? <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="rejection_reasons">{trans.rejects_reasons}</InputLabel>
                <Select
                      labelId="rejects_reasons"
                      id="rejects_reasons"
                      value={rejectReason}
                      label={trans.rejects_reasons}
                      onChange={(e) => setRejectReason(e.target.value)}>
                      <MenuItem value={trans.reject_reason_id_card}>{trans.reject_reason_id_card}</MenuItem>
                      <MenuItem value={trans.reject_reason_driving_license}>{trans.reject_reason_driving_license}</MenuItem>
                      <MenuItem value={trans.reject_reason_driving_license_expired}>{trans.reject_reason_driving_license_expired}</MenuItem>
                      <MenuItem value={trans.reject_reason_profile_photo}>{trans.reject_reason_profile_photo}</MenuItem>
                      <MenuItem value={trans.reject_reason_other}>{trans.reject_reason_other}</MenuItem>
                </Select>
              </FormControl>
            </Grid> : null }

            {rejectReason == trans.reject_reason_other ?
              <Grid item xs={12}>  <TextField
                margin="dense"
                id="rejectReason"
                label={trans.reject_reason}
                value={rejectReasonOther}
                type="text"
                onChange={(e)=> {setRejectReasonOther(e.target.value)}}
                fullWidth
                variant="standard" />
                
                </Grid> : null }

          <Grid item xs={12}>
            <hr className='_split_line'/>
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default EditUserStatus;
