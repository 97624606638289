import {
  POST_BANNER_ALL,
  POST_BANNER_ADD,
  POST_BANNER_EDIT
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  bannersData: null,
  addBannerData: null,
  editBannerData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_BANNER_ALL:
               return {
                ...state,
               bannersData: action.payload
          }
          case POST_BANNER_ADD:
            return {
              ...state,
              addBannerData: action.payload
          }
          case POST_BANNER_EDIT:
            return {
              ...state,
              editBannerData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;