// Import the functions you need from the SDKs you need
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { DEBUG_MODE } from "../constants";
import Api from '../helpers/Api'
const ARG_FIREBASE_ADMIN = "topic_admin_all";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBA9WZQbo7Tc8F27N5Ocd3H2H_GlNQFyuE",
  authDomain: "youway-92004.firebaseapp.com",
  databaseURL: "https://youway-92004-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "youway-92004",
  storageBucket: "youway-92004.appspot.com",
  messagingSenderId: "701107470252",
  appId: "1:701107470252:web:c575a90c2b0b36824d8409",
  measurementId: "G-B395J7LBVG"
};

// Initialize Firebase
var firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const initializeFirebase = () => {
  firebaseApp = initializeApp(firebaseConfig);
}

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BAq5rcwwnxBHBp_u4F3r3QKeteip8dfx59tAg-ATf1x82QQikZjRAjmm_6-lFDi_qbaLb1nV1pVGiYO-UFTbKEs'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured

        subscribeToTopic(currentToken);

      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });

  export const subscribeToTopic = async (token) => {
    let topic  = `${DEBUG_MODE ? "debug_" : ""}${ARG_FIREBASE_ADMIN}`

    let postData = {topic,token};

    Api.post('/settings/subscribeToTopic', postData)
    .then(function (response) {
      if (DEBUG_MODE) {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (DEBUG_MODE) {
        console.log(error);
      }
    });
}

export const unSubscribeToTopic = async (token) => {

  let topic  = `${DEBUG_MODE ? "debug_" : ""}${ARG_FIREBASE_ADMIN}`

  let postData = {topic,token};

  Api.post('/settings/unsubscribeToTopic', postData)
  .then(function (response) {
    if (DEBUG_MODE) {
      console.log(response);
    }
  })
  .catch(function (error) {
    if (DEBUG_MODE) {
      console.log(error);
    }
  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // ...
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  } catch (error) {
    console.error(error);
  }
}