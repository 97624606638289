import React from 'react';

const UserStatusText = (user,trans) => {
  var text, color;  
  if (user.admin_checked && user.approved){
    text =  trans.account_status_approved;
    color = "green";
  } else if (user.admin_checked && !user.approved) {
    text =  trans.account_status_rejected
    color = "red";
  } else {
    text = trans.account_status_pending;
    color = "#ffb300";
  }

  return <div style={{color: color}}>
    <b>{text}</b>
  </div>;
}

export default UserStatusText;