import { getUserLocales } from 'get-user-locale';
import * as Const from '../constants';
import * as Store from '../helpers/Store';
import { USER_ID_SET, USER_TOKEN_SET } from '../redux/actionTypes';
import Moment from 'moment';

export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const validURL =(str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const getLocale = () => {
  var locale = getUserLocales()[0];
  if (locale.includes("-")){
    locale = locale.split('-')[0];
  }
  return locale;
}

export const isLoggedIn = () => {
  const userId = Store.getData(USER_ID_SET);
  const token = Store.getData(USER_TOKEN_SET);
  let loggedIn = userId != null && token != null && userId != "" && token != "" && userId != undefined && token != undefined && userId != "null" && token != "null";
  return loggedIn;
}

export const logout = () => {
  Store.storeData(USER_ID_SET,null)
  Store.storeData(USER_TOKEN_SET,null)
}


export const getLightsMode = () => {
   var mode = 'light';
   if (Store.getData(Const.LIGHTS_MODE) != null && Store.getData(Const.LIGHTS_MODE) != ""){
    mode = Store.getData(Const.LIGHTS_MODE);
   }
   
   return mode;
}

export const storeLightsMode = (mode) => {
  Store.storeData(Const.LIGHTS_MODE,mode); 
}

export const getLocalDate = (date) => {
  var dateFormat = Const.DATE_FORMAT;
  var testDateUtc = Moment.utc(date);
  var localDate = testDateUtc.local();
 return localDate.format(dateFormat);
}

export const getLocalDateWithoutTime = (date) => {
  var dateFormat = Const.DATE_FORMAT_NO_TIME;
  var testDateUtc = Moment.utc(date);
  var localDate = testDateUtc.local();
 return localDate.format(dateFormat);
}


export const addZeroToNumbers = (num) => {
  var regExp = /^0[0-9].*$/;
   if (!regExp.test(num) && num <= 9){
      return `${0}${num}`;
   }
   return num;
}


export const convertBigNumber = (num) =>{
  if(num > 999 && num < 1000000){
      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  }else if(num > 1000000){
      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  }else if(num < 900){
      return num; // if value < 1000, nothing to do
  }
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
}


export const getUserTypeText = (userType, trans) => {
  if (userType == 1){
    return trans.userTypeDriver;
  } else if (userType == 2) {
    return trans.userTypePassenger;
  } else {
    return trans.userTypeBoth;
  }
}

export const isItemValid = (item) => {
  if (item != null && item != "" && item != 0 && item != "#") {
    return true;
  } 
  return false;
}