import './edit-users.css';
import React, { useState,useEffect,CSSProperties } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT, POST_CREATE_ACCOUNTS } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postEditUsersAdd, postCompaniesAll, postEditUsersEdit, } from '../../redux/company/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SweetAlert from 'sweetalert2';

import { getLocalDate } from '../../helpers/General';
import { PRIMARY_COLOR } from '../../constants';

import HashLoader from "react-spinners/HashLoader";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { postCreateAccounts, postEditAccounts } from '../../redux/auth/action';
import CompanyPicker from '../company/CompanyPicker';

import { TextField } from '@mui/material';
import PackagesPicker from '../import-users/PackagesPicker';
import { postUsersAll } from '../../redux/users/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const EditUsers = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const createAccountsData = useSelector(({auth}) => auth.createAccountsData);
  const usersData = useSelector(({users}) => users.usersData);


  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [loadingImport,setLoadingImport] = useState(false);
  const [importFinished,setImportFinished] = useState(false);
  const [selectedCompanies,setSelectedCompanies] = useState([]);
  const [selectedPackages,setSelectedPackages] = useState([]);
  const [availableRoutes, setAvailableRoutes] = useState("0");
  const [usersArr,setUsersArr] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const [searchUser,setSearchUser] = useState(null);
  const [toChangeCopmanies,setToChangeCompanies] = useState(false);
  const [toChangePackages,setToChangePackages] = useState(false);
  const [toChangeAvailableRoutes,setToChangeAvailableRoutes] = useState(false);


  //HOOKS
  useEffect(() => { //ONLOAD
    getUserData();
  }, []);

  const getUserData = () => {
    let postData = {
      hasPaginate: false,
      searchKeyword: searchUser,
    };

    dispatch(postUsersAll(postData)); 
  }


  useEffect(() => {
    if (usersData != null){
       setUsersArr(usersData.users);
      
    } 
  },[usersData]);

  useEffect(() => { //ONLOAD
      if (createAccountsData != null){
        setLoadingImport(false);
        if (createAccountsData.status) {
          setImportFinished(true);
          toast.dismiss();
          toast.success(createAccountsData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              
        } else {
          toast.dismiss();
          toast.error(createAccountsData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_CREATE_ACCOUNTS, payload: null});
      }
   }, [createAccountsData]);
 

const onEditUsers = (e) => {
  e.preventDefault();
  SweetAlert.fire({
    theme: "dark",
    title:trans.button_edit_users,
    text:trans.edit_users_desc,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: PRIMARY_COLOR,
    confirmButtonText:trans.edit_users_btn,
    cancelButtonText: trans.cancel_btn,
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
        //OK
        //REMOVE
        multiCreateUsers();
    
    }
  })
}

  const multiCreateUsers = () => {

    let users = [];
    usersFilter.forEach(element => {
      users.push(element.id)
    });

    let postData = {
      editRoutes: toChangeAvailableRoutes,
      avRoutes: availableRoutes,
      editPack: toChangePackages,
      packages: selectedPackages,
      editCompany: toChangeCopmanies,
      companies: selectedCompanies,
      users
    };

    setLoadingImport(true);

    dispatch(postEditAccounts(postData));
  }

  const onCompaniesSelectChange = (selected) => {
    setSelectedCompanies(selected);
  }

  const onPackagesSelectChange = (selected) => {
    setSelectedPackages(selected);
  }


  const handleUsersChange = (e,selectedValues) => {
    setUsersFilter(selectedValues);
    setSearchUser("");
  }

  const handleSearchInput = (value) => {
    setSearchUser(value);
  }


  useEffect(() => {
    if (searchUser != null){
      getUserData();
    } 
  },[searchUser]);




  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_edit_users}</CustomTitle>
        </div>

        <Grid container spacing={3} alignItems="center">

        <Grid item xs={12} >
          <h4>{trans.edit_users_select_users}</h4>
        </Grid>

        <Grid item xs={12} >
              <Autocomplete
                    multiple
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                    disableCloseOnSelect
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullName}
                    onInputChange={(event, newInputValue) => {
                      handleSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullName}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
            </Grid>
   
        {usersFilter.length > 0 && loadingImport == false ?
        <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.to_change_available_routes}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={toChangeAvailableRoutes}
                          label={trans.to_change_available_routes}
                          onChange={(e) => setToChangeAvailableRoutes(e.target.value)}>
                          <MenuItem key={`to_change_available_routes_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`to_change_available_routes_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid> : null }

        {usersFilter.length > 0 && loadingImport == false ? toChangeAvailableRoutes ?   <Grid item xs={6} >

            <TextField
                    margin="dense"
                    id="availableRoutes"
                    label={trans.upload_users_select_available_routes}
                    value={availableRoutes}
                    type="number"
                    onChange={(e)=> {setAvailableRoutes(e.target.value)}}
                    fullWidth
                    variant="standard" />
          </Grid> : null : null}

        {usersFilter.length > 0 && loadingImport == false ?
        <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.to_change_packages}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={toChangePackages}
                          label={trans.to_change_packages}
                          onChange={(e) => setToChangePackages(e.target.value)}>
                          <MenuItem key={`to_change_packages_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`to_change_packages_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid> : null }

        {usersFilter.length > 0 && loadingImport == false ? toChangePackages ?   <Grid item xs={12} >
              <div>
                <h4>{trans.upload_users_select_package}</h4>
                <PackagesPicker onPackagesSelectChange={onPackagesSelectChange}/>
              </div>
          </Grid> : null : null}

          {usersFilter.length > 0 && loadingImport == false ?
        <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.to_change_companies}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={toChangeCopmanies}
                          label={trans.to_change_companies}
                          onChange={(e) => setToChangeCompanies(e.target.value)}>
                          <MenuItem key={`to_change_company_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`to_change_company_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid> : null }

        {usersFilter.length > 0 && loadingImport == false ? toChangeCopmanies ?   <Grid item xs={12} >
              <div>
              <h4>{trans.upload_users_select_company}</h4>
              <CompanyPicker onCompaniesSelectChange={onCompaniesSelectChange}/>
              </div>
          </Grid> : null : null}

        </Grid>
      
        <div>
        <br/>

      
      



        {usersFilter.length > 0 && loadingImport == false ? <div style={{float: 'right'}}>
          <Button startIcon={<ImportExportIcon />} size="small" color="primary" onClick={(e) => onEditUsers(e)}  variant="outlined">{trans.edit_users_btn}</Button>
        </div> : null }
     

        {loadingImport == true && importFinished != true ? 
          <div> 
           <div>{trans.import_users_wait_desc}</div> 
           <br/>
          <HashLoader
                  color={"orange"}
                  loading={loadingImport}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
          </div> :
          null
        }

        </div>
      </Paper>
    </Grid>
  </Grid>

        

</Container>);
}

export default EditUsers;