import {
  POST_POPUP_ALL,
  POST_POPUP_ADD,
  POST_POPUP_EDIT,
  POST_POPUP_DELETE,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postPopupsAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`popup/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_POPUP_ALL, payload: response.data});
};

    
export const postPopupsAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`popup/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_POPUP_ADD, payload: response.data});
};

export const postPopupsEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`popup/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_POPUP_EDIT, payload: response.data});
};


export const postPopupsDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`popup/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_POPUP_DELETE, payload: response.data});
};


