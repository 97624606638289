import {
  POST_POPUP_ALL,
  POST_POPUP_ADD,
  POST_POPUP_EDIT,
  POST_POPUP_DELETE
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  popupsData: null,
  addPopupData: null,
  editPopupData: null,
  deletePopupData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_POPUP_ALL:
               return {
                ...state,
                popupsData: action.payload
          }
          case POST_POPUP_ADD:
            return {
              ...state,
              addPopupData: action.payload
          }
          case POST_POPUP_EDIT:
            return {
              ...state,
              editPopupData: action.payload
          }
          case POST_POPUP_DELETE:
            return {
              ...state,
              deletePopupData: action.payload
          }
          
        default:
              return state;
        }
};
      
export default reducer;