import {
  POST_COMPANY_ALL,
  POST_COMPANY_ADD,
  POST_COMPANY_EDIT
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  companiesData: null,
  addCompanyData: null,
  editCompanyData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_COMPANY_ALL:
               return {
                ...state,
                companiesData: action.payload
          }
          case POST_COMPANY_ADD:
            return {
              ...state,
              addCompanyData: action.payload
          }
          case POST_COMPANY_EDIT:
            return {
              ...state,
              editCompanyData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;