import {
  POST_COMPANY_ALL,
  POST_COMPANY_ADD,
  POST_COMPANY_EDIT,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postCompaniesAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`company/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COMPANY_ALL, payload: response.data});
};

    
export const postCompanyAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`company/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COMPANY_ADD, payload: response.data});
};

export const postCompanyEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`company/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COMPANY_EDIT, payload: response.data});
};

