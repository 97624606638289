import './users.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { PRIMARY_COLOR } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_USERS_ADD, POST_USERS_CHANGE_ACCESS_PACKAGES, POST_USERS_CHANGE_APPROVED, POST_USERS_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postUsersAll, postUsersChangeAccessPackages, postUsersChangeApproved, postUsersChangeCompanies, postUsersChangeRoutes } from '../../redux/users/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './editUserStatus';
import TablePagination from '@mui/material/TablePagination';
import InventoryIcon from '@mui/icons-material/Inventory';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import { getLocalDate, getUserTypeText } from '../../helpers/General';
import UserStatusText from './UserStatusText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditUserPackages from './editUserPackages';
import EditUserCompanies from './EditUserCompanies';
import EditUserRoutes from './EditUserRoutes';
import { useParams } from 'react-router-dom';
import * as General from '../../helpers/General'
import axios from '../../helpers/Api'

const Users = () => {

  var passedUserId = "";

  let { userId } = useParams();
  if (userId != null){
    passedUserId = userId;
  }

  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const usersData = useSelector(({users}) => users.usersData);
  const addCategoryData = useSelector(({users}) => users.addCategoryData);
  const editCategoryData = useSelector(({users}) => users.editCategoryData);
  const changeUserApprovedData = useSelector(({users}) => users.changeUserApprovedData);
  const changeUserPackagesData = useSelector(({users}) => users.changeUserPackagesData);
  const changeUserCopmaniesData = useSelector(({users}) => users.changeUserCopmaniesData);
  const changeUserRoutesData = useSelector(({users}) => users.changeUserRoutesData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [usersArr,setUsersArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");

  const [addModal, setAddModal] = useState(false);
  const [userPackageModal, setUserPackageModal] = useState(false);
  const [userCompaniesModal, setUserCopmaniesModal] = useState(false);
  const [userRoutesModal, setUserRoutesModal] = useState(false);

  const [toGetData, setToGetData] = useState(false);
  const [toGetUserPackagedData, setToGetUserPackagedData]  = useState(false);
  const [toGetUserCompaniesData, seToGetUserCompaniesData]  = useState(false);
  const [toGetUserRoutesData, seToGetUserRoutesData]  = useState(false);


  const [toClearData, setToClearData] = useState(false);
  const [toClearUserPackagesData, setToClearUserPackagesData] = useState(false);
  const [toClearUserCompaniesData, setToClearUserCompaniesData] = useState(false);
  const [toClearUserRoutesData, setToClearUserRoutesData] = useState(false);

  

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [userTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
   checkForPassedData();
  }, []);

 const checkForPassedData = async () => {
    if (passedUserId != ""){
     // console.log("passedUserId",passedUserId);
      const userLocale = General.getLocale();
      let postData = {};
      postData.langLoc = userLocale;
      postData.toCheckInfo = false;
      postData.userId = passedUserId;
    
      const response = await axios.post(`account/userinfo`,postData);

    
      if (response.data != null && response.data.userInfo != null){
        setToClearData(false);
        setDialogTitle(trans.edit_item_title)
        setEditItem(response.data.userInfo);
        setAddModal(true);
      }

    }
  }

  useEffect(() => { //GOT DATA
    if (usersData != null){
      // console.log("usersData",usersData);
      setUsersArr(usersData.users);
      setTotalData(usersData.total);
      setLoadedFirstTime(true);
    }
  }, [usersData]);

  useEffect(() => { //ADD DATA
    if (addCategoryData != null){
      if (addCategoryData.status) {
        toast.dismiss();
        toast.success(addCategoryData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addCategoryData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_USERS_ADD, payload: null});
    }
  }, [addCategoryData]);

  useEffect(() => { //EDIT DATA
    if (editCategoryData != null){
      if (editCategoryData.status) {
        toast.dismiss();
        toast.success(editCategoryData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editCategoryData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_USERS_EDIT, payload: null});
    }
  }, [editCategoryData]);

  useEffect(() => { //DELETE DATA
    if (changeUserApprovedData != null){
      if (changeUserApprovedData.status) {
        toast.dismiss();
        toast.success(changeUserApprovedData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(changeUserApprovedData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_USERS_CHANGE_APPROVED, payload: null});
    }
  }, [changeUserApprovedData]);

  useEffect(() => { //DELETE DATA
    if (changeUserPackagesData != null){
      if (changeUserPackagesData.status) {
        toast.dismiss();
        toast.success(changeUserPackagesData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(changeUserPackagesData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_USERS_CHANGE_ACCESS_PACKAGES, payload: null});
    }
  }, [changeUserPackagesData]);


  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
  useEffect(() => { 
    if (userTypeSelect != null && userTypeSelect && userTypeSelect != 0){
      reloadData();
    }
  }, [userTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      hasPaginate: true,
      formApp: false,
      page:page + 1,
      limit: rowsPerPage,
      sort,
      searchKeyword: searchKeyword,
    };

    if (userTypeSelect != 0 && userTypeSelect != "all"){
      postData["userTypeSelect"] = userTypeSelect;
    }

    if (accountStatusSelect != "all" ){
      postData["accountStatusSelect"] = accountStatusSelect;
    }

    dispatch(postUsersAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_item_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onUserPackageDialogClose = () => {
    setUserPackageModal(false);
  }

  const onUserCompaniesDialogClose = () => {
    setUserCopmaniesModal(false);
  }

  const onUserRoutesDialogClose = () => {
    setUserRoutesModal(false);
  }


  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onDialogUserPackagesSubmit = () => {
    setToGetUserPackagedData(true);
  }

  const onDialogUserCompaniesSubmit = () => {
    seToGetUserCompaniesData(true);
  }

  const onDialogUserRoutesSubmit = () => {
    seToGetUserRoutesData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postUsersChangeApproved(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } 
  }

  const onSubmitUserPackagesData = (data) => {
    let postData = data;
    if (editItem != null){
      postData.userId = editItem.id;
      setEditItem(null);
      dispatch(postUsersChangeAccessPackages(postData));
      setToGetUserPackagedData(false);
      setToClearUserPackagesData(false);
      onUserPackageDialogClose();
    } 
  }

  const  onSubmitUserCompaniesData = (data) => {
    let postData = data;
    if (editItem != null){
      postData.userId = editItem.id;
      setEditItem(null);
      dispatch(postUsersChangeCompanies(postData));
      seToGetUserCompaniesData(false);
      setToClearUserCompaniesData(false);
      onUserCompaniesDialogClose();
    } 
  }

  const  onSubmitUserRoutesData = (data) => {
    let postData = data;
    if (editItem != null){
      postData.userId = editItem.id;
      setEditItem(null);
      dispatch(postUsersChangeRoutes(postData));
      seToGetUserRoutesData(false);
      setToClearUserRoutesData(false);
      onUserRoutesDialogClose();
    } 
  }






  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  

  const onEditRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_item_title)
    setEditItem(item);
    setAddModal(true);
  }

  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_item_title)
    setEditItem(item);
    setAddModal(true);
  }



  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  //UI
  const columnData = [
    {id: "", align: true, disablePadding: false, label: ''},
    {id: "name", align: true, disablePadding: false, label: trans.table_head_fullname},
    {id: "age", align: true, disablePadding: false, label: trans.table_head_age},
    {id: "usertype", align: true, disablePadding: false, label: trans.table_head_usertype},
    {id: "email", align: true, disablePadding: false, label: trans.table_head_email},
    {id: "av_routes_num", align: true, disablePadding: false, label: trans.table_av_routes_num},
    {id: "companies", align: true, disablePadding: false, label: trans.table_head_company},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at},
    {id: "approved", align: true, disablePadding: false, label: trans.table_head_account_status}
  ];


  const onChangeUserType = (value) => {
    setSearchTypeSelect(value);
  }
  
  const onChangeAccountStatus = (value) => {
    setAccountStatusSelect(value);
  }

  const onPackagesButton = (e,item) => {
    e.preventDefault();
    setToClearUserPackagesData(false);
    setEditItem(item);
    setUserPackageModal(true);
  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <CustomTitle>{trans.menu_users}</CustomTitle>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={8}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard" 
                disabled={(userTypeSelect != 0 && userTypeSelect != "all") || (accountStatusSelect != "none"  && accountStatusSelect != "all") }
                onChange={(e) => searchChanged(e.target.value)}
              />
          </Grid>   
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.user_type_select}</InputLabel>
              <Select
                    variant="standard"
                    labelId="userTypeSelect"
                    id="userTypeSelect"
                    value={userTypeSelect}
                    label={trans.user_type_select}
                    onChange={(e) => onChangeUserType(e.target.value)}>
                      <MenuItem key={"user_type_0"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"user_type_1"} value={1}>{trans.userTypeDriver}</MenuItem> 
                      <MenuItem key={"user_type_2"} value={2}>{trans.userTypePassenger}</MenuItem>
                      {/* <MenuItem key={"user_type_3"} value={3}>{trans.userTypeBoth}</MenuItem> */}
                </Select>
              </FormControl>
          </Grid>
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.table_head_account_status}</InputLabel>
              <Select
                    variant="standard"
                    labelId="accountStatusSelect"
                    id="accountStatusSelect"
                    value={accountStatusSelect}
                    label={trans.table_head_account_status}
                    onChange={(e) => onChangeAccountStatus(e.target.value)}>
                          <MenuItem key={"account_status_none"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"account_status_aproved"} value={"approved"}>{trans.account_status_approved}</MenuItem> 
                      <MenuItem key={"account_status_rejected"} value={"rejected"}>{trans.account_status_rejected}</MenuItem>
                      <MenuItem key={"account_status_pending"} value={"pending"}>{trans.account_status_pending}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
       </Grid>
       
        <div>
         <br/>
          <Table size="small" style={{display:'block', overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                <TableCell/>
                <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {usersArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.img_thumb_url == "#" ? null : <img className='_users_user_img' alt={`user_${row.fullName}_img`} src={row.img_thumb_url} width="30" height="30"></img>}</TableCell>
                    <TableCell>{row.fullName}</TableCell>
                    <TableCell>{row.age}</TableCell>
                    <TableCell>{getUserTypeText(row.usertype, trans)}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.av_routes_num}</TableCell>
                    <TableCell>{row.companies != null && row.companies.length > 0 ? row.companies[0].title : "N/A"}</TableCell>
                    <TableCell>{getLocalDate(row.created_at)}</TableCell>
                    <TableCell>{UserStatusText(row,trans)}</TableCell>    
                    <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onViewRow(e,row)}  variant="outlined">{trans.view_item_title}</Button></TableCell>
                    <TableCell><Button startIcon={<InventoryIcon />} size="small" color="secondary" onClick={(e) => onPackagesButton(e,row)}  variant="outlined">{trans.view_item_packages}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${trans.edit_user_status_title}`}
        body={<EditUserStatus toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

    <FormDialog
        open={userPackageModal}
        onClose={onUserPackageDialogClose}
        title={`${trans.edit_user_packages_title}`}
        body={<EditUserPackages toGetData={toGetUserPackagedData} toClearData={toClearUserPackagesData} onSubmitData={onSubmitUserPackagesData} editItem={editItem}/> }
        onSubmit={onDialogUserPackagesSubmit}
        maxWidth={"md"}
      />        

    <FormDialog
        open={userCompaniesModal}
        onClose={onUserCompaniesDialogClose}
        title={`${trans.edit_user_companies_title}`}
        body={<EditUserCompanies toGetData={toGetUserCompaniesData} toClearData={toClearUserCompaniesData} onSubmitData={onSubmitUserCompaniesData} editItem={editItem}/> }
        onSubmit={onDialogUserCompaniesSubmit}
        maxWidth={"md"}
      />          

  <FormDialog
        open={userRoutesModal}
        onClose={onUserRoutesDialogClose}
        title={`${trans.edit_user_routes_title}`}
        body={<EditUserRoutes toGetData={toGetUserRoutesData} toClearData={toClearUserRoutesData} onSubmitData={onSubmitUserRoutesData} editItem={editItem}/> }
        onSubmit={onDialogUserRoutesSubmit}
        maxWidth={"md"}
      />          

</Container>);
}

export default Users;