import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/popups/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditBannerItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
   
 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [titles, setTitles] = useState([]);
    const [descs, setDescs] = useState([]);
    const [position,setPosition] = useState("WELCOME");
    const [status,setStatus] = useState(true);
  
    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;

   
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

         // console.log("postdata",postData);

          props.onSubmitData(postData);
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {
      let postData = {
        "position": position,
        "titles": titles,
        "descs": descs,
        "status": status
      };

      return postData;
    }

    useEffect(() => {
      if (editItem != null){
          setTitles(editItem.titles);
          setDescs(editItem.descs);
          setPosition(editItem.position);
          setStatus(editItem.status);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setPosition("WELCOME");
        setTitles([]);
        setDescs([]);
        setStatus(true);
    }

 
    const handleTitleChange = (langValue, newValue) => {
      setTitles((prevTitles) => {
        const index = prevTitles.findIndex((item) => item.lang === langValue);
        if (index !== -1) {
          const updatedTitles = [...prevTitles];
          updatedTitles[index] = { ...updatedTitles[index], value: newValue };
          return updatedTitles;
        } else {
          return [...prevTitles, { lang: langValue, value: newValue }];
        }
      });
    };
  
    const handleDescChange = (langValue, newValue) => {
      setDescs((prevDescs) => {
        const index = prevDescs.findIndex((item) => item.lang === langValue);
        if (index !== -1) {
          const updatedDescs = [...prevDescs];
          updatedDescs[index] = { ...updatedDescs[index], value: newValue };
          return updatedDescs;
        } else {
          return [...prevDescs, { lang: langValue, value: newValue }];
        }
      });
    };

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      
        {settingsData.settings.langs.map((lang) => (
          <Grid key={`popup_content_${lang.value}`} item xs={12}>
            <img src={lang.icon} alt={lang.name} style={{ width: '20px', marginRight: '5px' }} />
            <TextField
              margin="dense"
              id={`title_${lang.id}`}
              label={`${lang.name} Title`}
              value={titles.find((title) => title.lang === lang.value)?.value || ''}
              type="text"
              onChange={(e) => handleTitleChange(lang.value, e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              id={`desc_${lang.id}`}
              label={`${lang.name} Description`}
              value={descs.find((desc) => desc.lang === lang.value)?.value || ''}
              type="text"
              onChange={(e) => handleDescChange(lang.value, e.target.value)}
              fullWidth
              variant="standard"
            />
          </Grid>
        ))}

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="visible_state">{trans.table_head_position}</InputLabel>
                <Select
                    labelId="position"
                    id="position"
                    value={position}
                    label={trans.table_head_position}
                    onChange={(e) => setPosition(e.target.value)}>{
                          settingsData.settings.popup_positions.map(popupPosition => {
                            return <MenuItem key={`menu_popup_item_${popupPosition}`} value={popupPosition}>{popupPosition}</MenuItem>
                          })
                    }
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

        </Grid>
      </div>
    );
}

export default EditBannerItem;
