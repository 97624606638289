import './notifications.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_NOTIFICATION_ADD } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postNotificationsAdd, postNotificationsAll, } from '../../redux/notifications/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './EditNotificationItem';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';

const NotificationsListPage = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const notificationsData = useSelector(({notifications}) => notifications.notificationsData);
  const addNotificationData = useSelector(({notifications}) => notifications.addNotificationData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [notificationsArr,setNotificationsArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [bannerTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (notificationsData != null){
      //console.log("notificationsData",notificationsData);
      setNotificationsArr(notificationsData.userPushItems);
       setTotalData(notificationsData.total);
      setLoadedFirstTime(true);
    }
  }, [notificationsData]);

  useEffect(() => { //ADD DATA
    if (addNotificationData != null){
      if (addNotificationData.status) {
        toast.dismiss();
        toast.success(addNotificationData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addNotificationData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_NOTIFICATION_ADD, payload: null});
    }
  }, [addNotificationData]);


  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
  useEffect(() => { 
    if (bannerTypeSelect != null && bannerTypeSelect && bannerTypeSelect != 0){
      reloadData();
    }
  }, [bannerTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromAdmin: true,
      page: page + 1,
      limit: rowsPerPage
    };

    dispatch(postNotificationsAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_banner_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
      let postData = data; 
      setEditItem(null);
      dispatch(postNotificationsAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);

    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };



  //UI
  const columnData = [
    {id: "title", align: true, disablePadding: false, label: trans.table_head_title},
    {id: "receiver", align: true, disablePadding: false, label: trans.table_head_reciever},
    {id: "body", align: true, disablePadding: false, label: trans.table_head_body},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at}
  ];


  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_notifications}</CustomTitle>
          <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button>
        </div>
        <br/>
      
        <div>
         <br/>
          <Table size="small" >
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                </TableRow>
            </TableHead>
            <TableBody>
                {notificationsArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.receiver != null ? row.receiver.fullName : ""}</TableCell>
                    <TableCell>{row.body}</TableCell>
                    <TableCell>{getLocalDate(row.created_at * 1000)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_notitification_title : trans.add_notitification_title }`}
        body={<EditUserStatus toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

</Container>);
}

export default NotificationsListPage;