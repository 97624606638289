import './companies.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postCompanyAdd, postCompaniesAll, postCompanyEdit, } from '../../redux/company/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './EditCompanyItem';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CompanyPicker = (props) => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const companiesData = useSelector(({companies}) => companies.companiesData);
  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [companiesArr,setCompanyArr] = useState([]);
  const [selectedCompanies,setSelectedCompanies] = useState([]);


  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (companiesData != null){
      setCompanyArr(companiesData.companies);
    }
  }, [companiesData]);

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false
    };

    dispatch(postCompaniesAll(postData)); 
  }



  const handleOnSelected = (e, item) => {
     
    // var updatedList = [...selectedCompanies];
    // var itemExists = false;
    // var itemIndex = 0;

    // updatedList.forEach((element,index) => {
    //   if (element == item.id){
    //     itemExists = true;
    //     itemIndex = index;
    //   } 
    // });

    // if (itemExists){
    //   updatedList.splice(itemIndex,1);
    // } else {
    //   updatedList.push(item.id);
    // }

    var updatedList = [];
    updatedList.push(item.id);

    setSelectedCompanies(updatedList);
    props.onCompaniesSelectChange(updatedList);

  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>

    {companiesArr.map((company) => {
      return <div key={company.id}>
        <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={ selectedCompanies.find(element => element == company.id) != null}
            onChange={(e,value) => handleOnSelected(e,company)}
            />
        
        {company.title}
        
        </div>
    })}

  </Grid>      

</Container>);
}

export default CompanyPicker;