import {combineReducers} from 'redux';


import settings from './settings/reducer';
import auth from './auth/reducer';
import categories from './categories/reducer';
import sources from './sources/reducer';
import upload from './upload/reducer';
import tags from './tags/reducer';
import posts from './posts/reducer';
import socialtemplates from './socialtemplate/reducer';
import users from './users/reducer';
import banners from './banners/reducer';
import notifications from './notifications/reducer';
import countries from './countries/reducer';
import contacts from './contacts/reducer';
import companies from './company/reducer';
import vehicles from './vehicles/reducer';
import admin from './admin/reducer';
import popups from './popups/reducer';

export default combineReducers({
    settings: settings,
    auth: auth,
    categories: categories,
    sources: sources,
    upload: upload,
    tags: tags,
    posts: posts,
    socialtemplates: socialtemplates,
    users: users,
    banners: banners,
    notifications: notifications,
    countries: countries,
    contacts:contacts,
    companies: companies,
    vehicles: vehicles,
    admin: admin,
    popups: popups
  });
