import './contacts.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_NOTIFICATION_ADD } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postContactsAll, } from '../../redux/contacts/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import MailIcon from '@mui/icons-material/Mail';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';

const ContactsListPage = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const contactsData = useSelector(({contacts}) => contacts.contactsData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [contactsArr,setContactsArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
 

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (contactsData != null){
      console.log("contactsData",contactsData);
      setContactsArr(contactsData.contacts);
       setTotalData(contactsData.total);
      setLoadedFirstTime(true);
    }
  }, [contactsData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
 
  //FUNCTIONS
  const getData = () => {
    let postData = {
      hasPaginate: true,
      page:page + 1,
      limit: rowsPerPage,
      sort,
    };

    dispatch(postContactsAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);

    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };



  //UI
  const columnData = [
    {id: "firstName", align: true, disablePadding: false, label: trans.table_head_firstName},
    {id: "lastName", align: true, disablePadding: false, label: trans.table_head_lastName},
    {id: "email", align: true, disablePadding: false, label: trans.table_head_email},
    {id: "message", align: true, disablePadding: false, label: trans.table_head_message},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at}
  ];

  const onClickRow = (e,item) => {
    e.preventDefault();
    window.location.href = `mailto:${item.email}?subject=${trans.menu_contact}`;
  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_contact}</CustomTitle>
        </div>
        <br/>
      
        <div>
         <br/>
          <Table size="small" >
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                 <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {contactsArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>{getLocalDate(row.created_at)}</TableCell>
                    <TableCell><Button startIcon={<MailIcon />} size="small" color="secondary" onClick={(e) => onClickRow(e,row)}  variant="outlined">{trans.contact_answer_button}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

</Container>);
}

export default ContactsListPage;