import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postCompanyAdd, postPackagesAll, postCompanyEdit, } from '../../redux/company/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PackagesPicker = (props) => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [packagesArr,setPackageArr] = useState([]);
  const [selectedPackages,setSelectedPackages] = useState([]);


  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);



  //FUNCTIONS
  const getData = () => {
    setPackageArr(settingsData.settings.pruchasePackages);

    var selectedList = [];
    settingsData.settings.pruchasePackages.forEach(element => {
      if (element.id == "silver_ticket_10_routes"){
        selectedList.push(element.id);
      }
      if (element.id == "golden_ticket_30_routes"){
        selectedList.push(element.id);
      }
      if (element.id == "platinum_ticket_730_routes"){
        selectedList.push(element.id);
      }
    });

    setSelectedPackages(selectedList);
    props.onPackagesSelectChange(selectedList);

  }



  const handleOnSelected = (item) => {
     
    var updatedList = [...selectedPackages];
    var itemExists = false;
    var itemIndex = 0;

    updatedList.forEach((element,index) => {
      if (element == item.id){
        itemExists = true;
        itemIndex = index;
      } 
    });

    if (itemExists){
      updatedList.splice(itemIndex,1);
    } else {
      updatedList.push(item.id);
    }

    setSelectedPackages(updatedList);
    props.onPackagesSelectChange(updatedList);

  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>

    {packagesArr.map((item) => {
      return <div key={item.id}>
        <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={ selectedPackages.find(element => element == item.id) != null}
            onChange={(e,value) => handleOnSelected(item)}
            />
        
          <span>{item.name} - {item.amount}€</span>
        
        </div>
    })}

  </Grid>      

</Container>);
}

export default PackagesPicker;