import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditBannerItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [position,setPosition] = useState("HOME");
    const [status,setStatus] = useState(true);
    const [has_expire,setHasExpire] = useState(false);
    const [expire_date,setExpireDate] = useState(new Date());
    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [width,setWidth] = useState(0);
    const [height,setHeight] = useState(0);
    const [fit,setFit] = useState("FIT");
    const [currentImage, setCurrentImage] = useState({url: "", file: null});
    const [web_link,setWebLink] = useState("");
   
    
    //REFS
    const fileInputRef=useRef();

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          if (currentImage.file != null){
            uploadImageFile();
          } else {
            props.onSubmitData(postData);
          }
        
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {
      let postData = {
        "position": position,
        "title": title,
        "status": status,
        "has_expire": has_expire,
        "expire_date":expire_date,
        "width": width,
        "height": height,
        "fit": fit,
        "web_link": web_link
      };

      if (uploadImageData != null){
        postData.img = uploadImageData.img;
        postData.img_thumb = uploadImageData.img_thumb;
      }

      return postData;
    }

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "banners");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }


    useEffect(() => {
      if (editItem != null){
          setTitle(editItem.title);
          setPosition(editItem.position);
          setStatus(editItem.status);
          setHasExpire(editItem.has_expire);
          setExpireDate(editItem.expire_date);
          setImg(editItem.img_url);
          setImgThumb(editItem.img_thumb_url);
          setWidth(editItem.width);
          setHeight(editItem.height);
          setFit(editItem.fit);
          setWebLink(editItem.web_link);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => { 
      console.log("uploadImageData",uploadImageData);
      if (uploadImageData != null){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              props.onSubmitData(postData);
            
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_IMAGE, payload: null});
      }
    }, [uploadImageData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setPosition("HOME");
        setTitle("");
        setStatus(true);
        setHasExpire(true);
        setExpireDate(new Date());
        setWidth(0);
        setHeight(0);
        setFit('FIT');
        setImg("");
        setImgThumb("");
        setWebLink("");
    }


    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }


    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="visible_state">{trans.table_head_position}</InputLabel>
                <Select
                    labelId="position"
                    id="position"
                    value={position}
                    label={trans.table_head_position}
                    onChange={(e) => setPosition(e.target.value)}>{
                          settingsData.settings.banner_positions.map(bannerPosition => {
                            return <MenuItem key={`menu_banner_item_${bannerPosition}`} value={bannerPosition}>{bannerPosition}</MenuItem>
                          })
                    }
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="has_expire">{trans.table_head_has_expire}</InputLabel>
                  <Select
                          labelId="has_expire"
                          id="has_expire"
                          value={has_expire}
                          label={trans.table_head_has_expire}
                          onChange={(e) => setHasExpire(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <DesktopDatePicker
                fullWidth
                label={trans.table_head_expire_date}
                value={expire_date}
                onChange={(newValue)=> setExpireDate(newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="visible_state">{trans.table_head_box_fit}</InputLabel>
                <Select
                    labelId="fit"
                    id="fit"
                    value={fit}
                    label={trans.table_head_box_fit}
                    onChange={(e) => setFit(e.target.value)}>{
                          settingsData.settings.box_fit_types.map(boxFitType => {
                            return <MenuItem key={`box_fit_${boxFitType}`} value={boxFitType}>{boxFitType}</MenuItem>
                          })
                    }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="widht"
                    label={trans.table_head_width}
                    value={width}
                    type="number"
                    onChange={(e)=> {setWidth(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="height"
                    label={trans.table_head_height}
                    value={height}
                    type="text"
                    onChange={(e)=> {setHeight(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="web_link"
                    label={trans.table_head_web_link}
                    value={web_link}
                    type="text"
                    onChange={(e)=> {setWebLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
                  <span>{trans.upload_banner_img_field}</span>
                  <div style={{marginTop:'15px'}}>
                    <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                    <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
            </Grid>

            
            <Grid item xs={12} >
              {currentImage.url == "" ? 
              <img style={{maxHeight:'550px',maxWidth:'100%'}} src={img}></img>
              : <img style={{maxHeight:'550px',maxWidth:'100%'}} src={currentImage.url}></img>}
                  
              </Grid>

        </Grid>
      </div>
    );
}

export default EditBannerItem;
