import {
  POST_EXPIRED_USERS,
} from '../actionTypes';

const INIT_STATE = {
  expiredUsersData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_EXPIRED_USERS:
                   return {
                     ...state,
                     expiredUsersData: action.payload
          }     
        default:
              return state;
        }
};
      
export default reducer;