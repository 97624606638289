import React, { useState,useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useLocation,Link,Route,Routes } from "react-router-dom";

import { Navigate, useParams } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ShareIcon from '@mui/icons-material/Share';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import WebIcon from '@mui/icons-material/Web';
import TagIcon from '@mui/icons-material/Tag';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import GroupIcon from '@mui/icons-material/Group';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';


import Categories from '../categories';
import Sources  from '../sources';
import Dashboard from '../dashboard';
import Tags from '../tags';
import * as General from '../../helpers/General'
import * as Store from '../../helpers/Store';
import Posts from '../posts';
import SweetAlert from 'sweetalert2';
import { PRIMARY_COLOR } from '../../constants';
import { postLogout } from '../../redux/auth/action';
import SocialTemplate from '../socialtemplate';
import Users from '../users';
import Banners from '../banners';
import NotificationsListPage from '../notifications';
import Caountries from '../countries';
import ContactsListPage from '../contact';
import Company from '../company';
import ImportUsers from '../import-users';
import EditUsers from '../edit-users';
import CarsPage from '../cars';
import Popups from '../popups';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


const Home = (props) => {

  //SELECTORS 
  const userInfoData = useSelector(({auth}) => auth.userInfoData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const logoutData = useSelector(({auth}) => auth.logoutData);
  
  //HOOKS VARIABLES
  const [darkModeChecked, setDarkModeChecked] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [open, setOpen] = useState(true);


  //VARIABLES
  const paramData = useParams();
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS
  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);

  useEffect(()=>{
    setSelectedMenu(paramData["*"]);
  },[paramData]);


  useEffect(()=>{
    if (logoutData != null){
      if (logoutData.status){
        window.location.reload();
      }
    }
  },[logoutData]);

  //FUNCTIONS
  const toggleDrawer = () => {
    setOpen(!open);
  };

 
  const askToLogoutUser = (e) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title:trans.logout_title,
      text:trans.logout_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          //LOGOUT
          let postData = {}
          dispatch(postLogout(postData));
      }
    })
  }


  const mainListItems = (
    <div>
      <ListItem button selected={selectedMenu == "dashboard"} component={Link} to="" >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_dashboard} />
      </ListItem>
      {/* <ListItem button  selected={selectedMenu == "categories"} component={Link} to="categories" >
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_categories} />
      </ListItem> */}
      {/* <ListItem button selected={selectedMenu == "sources"}  component={Link} to="sources" >
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_sources} />
      </ListItem>
      <ListItem button selected={selectedMenu == "tags"} component={Link} to="tags">
        <ListItemIcon>
          <TagIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_tags} />
      </ListItem> */}
      {/* <ListItem button selected={selectedMenu == "posts"} component={Link} to="posts">
        <ListItemIcon>
          <DynamicFeedIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_posts} />
      </ListItem> */}
      <ListItem button selected={selectedMenu == "users"}  component={Link} to="users">
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_users} />
      </ListItem>
      <ListItem button selected={selectedMenu == "companies"}  component={Link} to="companies">
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_companies} />
      </ListItem>
      <ListItem button selected={selectedMenu == "cars"}  component={Link} to="cars">
        <ListItemIcon>
          <DriveEtaIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_cars} />
      </ListItem>
      <ListItem button selected={selectedMenu == "importusers"}  component={Link} to="importusers">
        <ListItemIcon>
          <GroupAddIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_import_users} />
      </ListItem>
      <ListItem button selected={selectedMenu == "editusers"}  component={Link} to="editusers">
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_edit_users} />
      </ListItem>
    </div>
  );
  
  const secondaryListItems = (
    <div>
     
      <ListItem button selected={selectedMenu == "banners"}  component={Link} to="banners">
        <ListItemIcon>
          <AdUnitsIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_banners} />
      </ListItem>
      <ListItem button selected={selectedMenu == "popups"}  component={Link} to="popups">
        <ListItemIcon>
          <NearbyErrorIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_popups} />
      </ListItem>
      <ListItem button selected={selectedMenu == "countries"}  component={Link} to="countries">
        <ListItemIcon>
          <FlagCircleIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_countries} />
      </ListItem>
      <ListItem button selected={selectedMenu == "notifications"} component={Link} to="notifications">
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_notifications} />
      </ListItem>
      <ListItem button selected={selectedMenu == "contact"} component={Link} to="contact">
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_contact} />
      </ListItem>
      <ListItem button selected={selectedMenu == "logout"} onClick={(e) => {askToLogoutUser(e)}}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={trans.menu_sign_out} />
      </ListItem>
    </div>
  );
  
   const handleModeChange = (event) => {
     let checked = event.target.checked;
    setDarkModeChecked(event.target.checked);
    if (checked){
       General.storeLightsMode("dark")
    } else {
      General.storeLightsMode("light")
    }
    props.onModeChange(checked);
  }; 

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard v{process.env.REACT_APP_VERSION}
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <FormGroup style={{marginLeft:'8px'}}>
              <FormControlLabel control={<Switch   checked={darkModeChecked} onChange={handleModeChange} />} label={General.getLightsMode() == "dark" ? trans.dark_mode : trans.light_mode}/>
            </FormGroup>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}>
            <div style={{display:'flex'}}>
                <div>
                    <AccountBoxIcon fontSize="medium"/>
                </div>
                &nbsp;
                <div>
                    {userInfoData.userInfo.fullName}
                </div>
            </div>    
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
         
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
         
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <div>
                  <Routes>

                      <Route path="/" element={<Dashboard />}></Route>
                      <Route path='users' element={<Users/>}></Route>
                      <Route path='users/:userId' element={<Users/>}></Route>
                      <Route path='banners' element={<Banners/>}></Route>
                      <Route path='popups' element={<Popups/>}></Route>
                      <Route path='notifications' element={<NotificationsListPage/>}></Route>
                      <Route path='countries' element={<Caountries/>}></Route>
                      <Route path='contact' element={<ContactsListPage/>}></Route>
                      <Route path='companies' element={<Company/>}></Route>
                      <Route path='importusers' element={<ImportUsers/>}></Route>
                      <Route path='editusers' element={<EditUsers/>}></Route>
                      <Route path='cars' element={<CarsPage/>}></Route>
          
                      
                      {/* <Route path='categories' element={<Categories/>}></Route>
                      <Route path='sources' element={<Sources/>}></Route>
                      <Route path='tags' element={<Tags/>}></Route>
                      <Route path='posts' element={<Posts/>}></Route>
                      <Route path='socialtemplate' element={<SocialTemplate/>}></Route> */}
                      {/* <Route exact path='/sources' element={<Home/>}/>
                      <Route exact path='/tags' element={<Home/>}/> */}
                  </Routes>
                  </div>
         
        </Box>
      </Box>
  );
}

export default Home;


