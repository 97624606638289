import {
  POST_EXPIRED_USERS
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  

export const postExpiredUsers = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`admin/expired-users`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_EXPIRED_USERS, payload: response.data});
};