import {
  POST_VEHICLE_ALL,
  POST_VEHICLE_EDIT,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postVehiclesAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`uservehicle/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_VEHICLE_ALL, payload: response.data});
};

    
// export const postVehicleAdd = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;

//   const response = await axios.post(`company/add`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_VEHICLE_ADD, payload: response.data});
// };

export const postVehicleEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`uservehicle/edit-admin`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_VEHICLE_EDIT, payload: response.data});
};

